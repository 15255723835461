import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import RoundFilledButton, { RoundFilledButtonProps } from 'components/toolbox/croquis/Button/RoundFilled';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import { usePreventScroll } from 'hooks';
import { Gray_Light_150, Gray_Light_200, Gray_Light_900, Gray_Light_White, Typography, web_max_width } from 'styles';
import { safeAreaInsetBottom } from 'styles/mixin';

export interface BottomDialogButtonOption extends RoundFilledButtonProps {
  id?: string;
  onClick?: () => void;
}

export interface BottomDialogOption {
  image?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  buttons?: BottomDialogButtonOption[];
  disableBackdropClick?: boolean;
  canBackDropResolve?: boolean;
  children?: ReactNode;
  radius?: boolean;
  containerHeight?: string;
  /**
   * @description window scroll을 할 수 있게 하는지 여부
   * @default false
   */
  canScroll?: boolean;
  buttonDirection?: 'column' | 'row';
  paddingTop?: string;
  paddingBottom?: string;
  onClose?: () => void;
}

export interface BottomDialogProps extends BottomDialogOption {
  active?: boolean;
  handleOutsideClick?: () => void;
}

/** useBottomDialog 와 Dialog에 사용하기 위한 컴포넌트  */
export default function BottomDialog({
  image,
  title,
  text,
  buttons,
  disableBackdropClick,
  children,
  radius = true,
  buttonDirection,
  active,
  containerHeight,
  canScroll,
  paddingTop = '48px',
  paddingBottom = '24px',
  handleOutsideClick = () => undefined,
}: BottomDialogProps) {
  usePreventScroll(Boolean(canScroll) === false && Boolean(active) === true);
  return (
    <AnimatePresence>
      {active && (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            z-index: 9998;
          `}
        >
          <Overlay
            animate={{ background: 'rgba(0, 0, 0, 0.7)' }}
            {...(!disableBackdropClick && {
              onClick: handleOutsideClick,
            })}
          />
          <Container
            initial={{ translateY: '100%' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '100%' }}
            transition={{ type: 'tween' }}
            css={css`
              border-radius: ${!radius ? '0' : '8px 8px 0 0'};
              height: ${containerHeight};
              padding-top: ${paddingTop};
              ${safeAreaInsetBottom(paddingBottom)}
            `}
          >
            {children ? (
              children
            ) : (
              <>
                {image && image}
                {title && <Title className={Typography.HEAD_20_BOLD}>{title}</Title>}
                {text && <Text className={Typography.BODY_15_MEDIUM}>{text}</Text>}
                {buttons && (
                  <ButtonWrapper flexDirection={buttonDirection}>
                    {buttons.map((button_props) => (
                      <StyledRoundFilledButton key={button_props.id} {...button_props} />
                    ))}
                  </ButtonWrapper>
                )}
              </>
            )}
          </Container>
        </div>
      )}
    </AnimatePresence>
  );
}

const Overlay = styled(createDomMotionComponent('div'))`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  color: ${Gray_Light_900};
  padding: 0 24px;
  margin: 0 0 8px;
  white-space: pre-wrap;
`;

const Text = styled.div`
  color: ${Gray_Light_900};
  padding: 0 24px;
  margin: 0 0 16px;
  white-space: pre-wrap;
`;

const Container = styled(createDomMotionComponent('div'))`
  position: absolute;
  z-index: 9999;
  margin: 0 auto;
  width: 100%;
  max-width: ${web_max_width}px;
  background: ${Gray_Light_White};
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;
const ButtonWrapper = styled.div<{ flexDirection?: string }>`
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

const StyledRoundFilledButton = styled(RoundFilledButton)`
  margin: 8px 4px 0;
  width: 100%;
  display: block;
  cursor: pointer;
  background: ${(props) => props.variant === 'default' && Gray_Light_150};
  &:active {
    background: ${(props) => props.variant === 'default' && Gray_Light_200};
  }
`;
