import { useCallback, useEffect, useMemo, useState } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { IllustCompleted, IllustWarning } from 'components/svg/illust';
import BottomDialog, { BottomDialogButtonOption } from 'components/toolbox/BottomDialog';

import { I18nKey } from '@common/i18n';

import { AlertReturnValue, DialogBaseProps, DialogMessage, DialogMessageObject } from './';

function RecordAlert({ message, ok, title, type, resolve, dispose }: DialogMessageObject & DialogBaseProps) {
  const [active, setActive] = useState(true);
  const { t } = useTranslation('common');
  const proceed = useCallback(
    (value: AlertReturnValue) => {
      resolve?.(value);
      setActive(false);
      dispose?.();
    },
    [resolve, setActive, dispose],
  );
  const image = useMemo(() => {
    if (type === 'warning') {
      return <IllustWarning />;
    }
    if (type === 'complete') {
      return <IllustCompleted />;
    }
    return undefined;
  }, [type]);
  const handleOutsideClick = useCallback(() => {
    proceed('skip');
  }, [proceed]);

  const buttons = useMemo<BottomDialogButtonOption[]>(
    () => [
      {
        id: 'ok',
        children: ok || t('확인'),
        onClick: () => {
          proceed('ok');
        },
        size: 'lg',
        variant: 'default',
      },
    ],
    [proceed],
  );

  useEffect(() => {
    return () => {
      dispose?.();
    };
  }, [dispose]);

  return (
    <BottomDialog
      active={active}
      image={image}
      title={title}
      text={message}
      handleOutsideClick={handleOutsideClick}
      buttons={buttons}
    />
  );
}

export function renderAlert<N extends Namespace>(message: DialogMessage, ns?: N) {
  return ({ resolve, dispose }: DialogBaseProps) => {
    if (typeof message === 'object') {
      return ns ? (
        <RecordAlertWithT {...{ ...message, resolve, dispose, ns }} />
      ) : (
        <RecordAlert {...{ ...message, resolve, dispose }} />
      );
    }
    return ns ? (
      <RecordAlertWithT {...{ message, resolve, dispose, ns }} />
    ) : (
      <RecordAlert {...{ message, resolve, dispose }} />
    );
  };
}

function RecordAlertWithT<N extends Namespace>({
  title,
  message,
  ok,
  cancel,
  ns,
  ...props
}: DialogMessageObject & DialogBaseProps & { ns: N }) {
  const { t } = useTranslation(ns);
  const { t: tc } = useTranslation('common');
  return (
    <RecordAlert
      {...{
        ...props,
        title: title && t(title as I18nKey<N>),
        message: t(message as I18nKey<N>),
        ok: ok ? t(ok as I18nKey<N>) : tc('확인'),
        cancel: cancel && t(cancel as I18nKey<N>),
      }}
    />
  );
}
