import React from 'react';

const DialogWarningIcon = React.memo(({ width = 56, height = 56, ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 56 56' {...props}>
      <path fill='#F0E644' fillRule='evenodd' d='M33.899 3L.999 52.002h54.409L33.898 3z' clipRule='evenodd'></path>
      <path
        fill='#000'
        fillRule='evenodd'
        d='M27.936 36.507h4.137l1.625-14.039h-4.124l-1.638 14.04zm-.871 7.47h4.143l.528-4.56h-4.14l-.531 4.56z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
});

export default DialogWarningIcon;
