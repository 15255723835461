import React from 'react';

const DialogConfirmIcon = React.memo(({ width = 56, height = 56, ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 56 56' {...props}>
      <path
        fill='#FF67B5'
        fillRule='evenodd'
        d='M53.5 28.62c-1.43 14.36-14.222 26-28.576 26-14.354 0-24.712-11.64-23.134-26 1.577-14.359 14.37-26 28.575-26s24.563 11.641 23.134 26z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#000'
        fillRule='evenodd'
        d='M24.617 37.905l-8.572-9.238L19.001 25l5.913 6.378 11.64-10.837 2.881 3.575-14.818 13.789z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
});
export default DialogConfirmIcon;
