import { css } from '@emotion/react';

export const backgroundImage = (img_urls: { size1x: string; size2x?: string; size3x?: string }) => {
  const size1x = img_urls.size1x;
  const size2x = img_urls.size2x || img_urls.size1x;
  const size3x = img_urls.size3x || img_urls.size2x || img_urls.size1x;
  return css`
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(${size1x});
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      background-image: url(${size2x});
    }
    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
      background-image: url(${size3x});
    }
  `;
};

export const imageSize = (width: string, height: string) => css`
  width: ${width};
  height: ${height};
  background-size: ${width} ${height};
`;

export const safeAreaInsetBottomHeight = (value: string, filledHeight = '0px') => css`
  height: ${value};
  height: calc(${value} - (constant(safe-area-inset-bottom) + ${filledHeight}));
  height: calc(${value} - (env(safe-area-inset-bottom) + ${filledHeight}));
`;

export const safeAreaInsetBottom = (value = '0px') => css`
  padding-bottom: ${value};
  padding-bottom: calc(constant(safe-area-inset-bottom) + ${value});
  padding-bottom: calc(env(safe-area-inset-bottom) + ${value});
`;

export const safeAreaInsetTop = (value = '0px') => css`
  padding-top: ${value};
  padding-top: calc(constant(safe-area-inset-top) + ${value});
  padding-top: calc(env(safe-area-inset-top) + ${value});
`;

export const resetButton = css`
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &[disabled] {
    cursor: default;
  }
`;

export const invisibleScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
